const faqs = [
  {
    question: "Gibt es spezielle Voraussetzungen, die ich für die Registrierung erfüllen muss?",
    answer: "Nein. Sie müssen nur Name, E-Mail Adresse und Betrieb angeben.",
  },
  {
    question: "Dürfen alle im Betrieb die Prüfung vornehmen?",
    answer:
      "Nein. Um Leitern und Tritte prüfen zu dürfen, müssen Sie dazu befähigt sein. Die BGHW bietet Ihnen dafür ein kostenloses Online-Training an.",
  },
  {
    question: "Wie lange dauert eine Prüfung mit der App?",
    answer: "Eine intakte Leiter ist in der Regel in wenigen Minuten geprüft.",
  },
  {
    question: "Wie oft muss ich prüfen?",
    answer:
      "Ihr Unternehmen legt in der Regel fest, wie häufig geprüft werden muss. Die BGHW empfiehlt, Leitern & Tritte mindestens jährlich zu prüfen.",
  },
  {
    question: "Kann ich gespeicherte Prüfungsergebnisse ausdrucken?",
    answer: "Ja, sie können das jeweilige Prüfungsergebnis als PDF-Dokument herunterladen und im Anschluss ausdrucken.",
  },
  {
    question: "Ist die Registrierung und Nutzung wirklich kostenlos?",
    answer: "Ja. Die Nutzung der Web-App ist ein kostenloser Service der BGHW.",
  },
  {
    question: "Kann ich die Anwendung auch komplett offline verwenden?",
    answer: "Nein, eine aktive Internet-Verbindung ist momentan eine Voraussetzung.",
  },
];

export default function FAQ() {
  return (
    <div className="bg-white" id="faq-section">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Häufig gestellte Fragen</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div key={faq.question} className="pt-6">
                <dt>
                  <div className="flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                  </div>
                </dt>
                <div className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
